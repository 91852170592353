<template>
    <div class="privacy-wrpper">
        <p>
            “CUCC”尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，”CUCC”会按照本隐私权政策的规定使用和披露
            您的个人信息。但”CUCC”将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，
            ”CUCC”不会将这些信息对外披露或向第三方提供。”CUCC”会不时更新本隐私权政策。 您在同意”CUCC”服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于”CUCC”服务使用协议不可分割的一部分。
        </p>

        <h1>1. 适用范围</h1>
        <p>a) ”CUCC”帐号注册是在ERP系统完成个人信息录入；</p>
        <p>b) 在您使用”CUCC”网络服务，或访问”CUCC”平台网页时，”CUCC”自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；</p>
        <p>c) ”CUCC”通过合法途径从商业伙伴处取得的用户个人数据。</p>
        <p>您了解并同意，以下信息不适用本隐私权政策：</p>
        <p>a) 您在使用”CUCC”平台提供的搜索服务时输入的关键字信息；</p>
        <p>b) ”CUCC”收集到的您在”CUCC”发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；</p>
        <p>c) 违反法律规定或违反”CUCC”规则行为及”CUCC”已对您采取的措施。</p>

        <h1>2. 信息使用</h1>
        <p>a) ”CUCC”不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和”CUCC”（含”CUCC”关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。</p>
        <p>b) ”CUCC”亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何”CUCC”平台用户如从事上述活动，一经发现，”CUCC”有权立即终止与该用户的服务协议。</p>
        <p>c) 为服务用户的目的，”CUCC”可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与”CUCC”合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。</p>

        <h1>3. 信息披露</h1>
        <p>a) 经您事先同意，向第三方披露；</p>
        <p>b) 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</p>
        <p>c) 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</p>
        <p>d) 如您出现违反中国有关法律、法规或者”CUCC”服务协议或相关规则的情况，需要向第三方披露；</p>
        <p>e) 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；</p>
        <p>f) 在”CUCC”平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，”CUCC”有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。</p>
        <p>g) 其它”CUCC”根据法律、法规或者网站政策认为合适的披露。</p>

        <h1>4. 信息存储和交换</h1>
        <p>“CUCC”收集的有关您的信息和资料将保存在”CUCC”及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或”CUCC”收集信息和资料所在地的境外并在境外被访问、存储和展示。 </p>

        <h1>5. Cookie的使用</h1>
        <p>a) 在您未拒绝接受cookies的情况下，”CUCC”会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的”CUCC”平台服务或功能。”CUCC”使用cookies可为您提供更加周到的个性化服务，包括推广服务。  </p>
        <p>b) 您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的”CUCC”网络服务或功能。</p>
        <p>c) 通过”CUCC”所设cookies所取得的有关信息，将适用本政策。</p>

        <h1>6. 信息安全 </h1>
        <p>a) “CUCC”帐号均有安全保护功能，请妥善保管您的用户名及密码信息。”CUCC”将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。 </p>
        <p>
            b) 在使用”CUCC”网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。
            请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是”CUCC”用户名及密码发生泄露，请您立即联络”CUCC”客服，以便”CUCC”采取相应措施。
        </p>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
};
</script>

<style lang="stylus">
    .privacy-wrpper
        width 100%
        height 100%
        overflow-y auto
        padding 0.2rem
        p
            text-indent 2em
        h1
            font-size 0.28rem
            font-weight 800
            margin 0.2rem 0
</style>